import React from 'react'
import styled from 'styled-components'
import { CareerHistory } from 'types/common'
import { Column, Row } from 'components/common/layout'
import { BaseText, MediumText, SmallText } from 'components/common/text'
import { displayCareerPeriod } from 'utils/displays'
import { LineLimitedText } from 'components/scouts/profile-detail/desc-more-view'
import { Logo } from 'components/common/logo'
import { theme } from 'infra/theme'
import { WorkExperiencePeriodText } from 'components/scouts/profile/common/styles'
import { IsStartupTag } from 'components/scouts/common/user-info-tag'
import { TechStackList } from 'components/scouts/profile/common/tech-stack-list'
import { getUrlWithHttp } from 'utils/formatters'

export const CareerDetail: React.FC<{
  career: CareerHistory
  isNarrow?: boolean
}> = ({ career, isNarrow }) => {
  const imageSize = isNarrow ? 48 : 56
  return (
    <Container isNarrow={isNarrow}>
      <CareerHeader isNarrow={isNarrow}>
        <Row style={{ gap: 12 }}>
          <Logo
            size={imageSize}
            src={career.company.image}
            placeholder='/company-placeholder.svg'
          />
          <Column style={{ gap: 4 }}>
            <Row style={{ gap: 4, flexWrap: 'wrap' }}>
              <StartupName
                onClick={(e) => {
                  career.link &&
                    window.open(getUrlWithHttp(career.link), '_blank')
                  e.stopPropagation()
                }}
                hasLink={!!career.link}
                dangerouslySetInnerHTML={{ __html: career.company.name }}
              />
              {career.company.isStartup && <IsStartupTag />}
            </Row>
            <BaseText dangerouslySetInnerHTML={{ __html: career.position }} />
            {!!career.serviceAreas.length && (
              <SmallText
                style={{ color: theme.color.gray1 }}
                dangerouslySetInnerHTML={{
                  __html: career.serviceAreas
                    .map((item) => item.name)
                    .join(' ‧ '),
                }}
              />
            )}
          </Column>
        </Row>
        <WorkExperiencePeriodText>
          {displayCareerPeriod(career)}
        </WorkExperiencePeriodText>
      </CareerHeader>
      <CareerOptionalInfoBox isNarrow={isNarrow}>
        {career.techStacks.length > 0 && (
          <TechStackList techStacks={career.techStacks} />
        )}
        {career.description && (
          <LineLimitedText
            text={career.description}
            color={theme.color.black3}
          />
        )}
      </CareerOptionalInfoBox>
    </Container>
  )
}

const Container = styled(Column)<{ isNarrow?: boolean }>`
  gap: 12px;
  @media ${(p) => p.theme.deviceSize.desktop} {
    ${(p) => !p.isNarrow && 'gap: 10px;'}
  }
`

const CareerHeader = styled(Column)<{ isNarrow?: boolean }>`
  @media ${(p) => p.theme.deviceSize.desktop} {
    ${(p) =>
      !p.isNarrow
        ? 'justify-content: space-between; flex-direction: row; gap: 40px;'
        : 'gap: 4px;'}
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 12px;
  }
`

const StartupName = styled(MediumText)<{ hasLink: boolean }>`
  ${(p) =>
    p.hasLink &&
    `text-decoration: underline; color: ${p.theme.color.secondary}; cursor: pointer;`}
`

const CareerOptionalInfoBox = styled(Column)<{ isNarrow?: boolean }>`
  gap: 12px;
  @media ${(p) => p.theme.deviceSize.desktop} {
    ${(p) => !p.isNarrow && 'margin-left: 68px;'}
  }
`
